import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ProductPage from '../components/products/ProductPage';
import ProductsHeader from '../components/ProductsHeader';
import fetchProductsNoSSR from '../../integration/fetchProductsNoSSR';

const Search = (props) => {
  /* const { serverData = {} } = props;
  const { data } = serverData; */

  const [loading, setLoading] = useState(true);
  const [listResult, setListResult] = useState({});

  useEffect(async () => {
    setLoading(true);
    const p = await fetchProductsNoSSR()({
      paramInfo: {},
      location: get(props, 'location', {}),
    });
    setListResult(p);
    setLoading(false);
  }, [props]);

  return (
    <ProductsHeader title="Search results">
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '50vh',
            justifyContent: 'center',
            padding: '2rem',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ProductPage {...listResult?.data} />
      )}
    </ProductsHeader>
  );
};

Search.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Search;

/* export async function getServerData(req) {
  // no params needed, just query
  return fetchProducts()(req);
} */
